
import img1 from '../images/logo/piprotocol.png'
import img2 from '../images/logo/PixiaLogo.png'
import img3 from '../images/logo/comingsoon.png'
import img4 from '../images/logo/comingsoon.png'

const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
  
]

export default dataPartner;
