import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss'

SliderItem.propTypes = {
    item : PropTypes.object,
};

function SliderItem(props) {
    const {item} = props;

    return (
        <div className={`box-slider ${item.classAction}`}>
            <img className='bg-slider' src={item.bgImg} alt="NecroDAO" />
            <div className="box-slider__main">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-box">
                                <h1 className="title">{item.title}</h1>
                                <p className="sub-title">{item.desc}</p>
                                <div className="wrap-btn">
                                    <Link to="https://t.me/necroportal" className="tf-button-st2 btn-effect"><span className="effect">Join Telegram</span></Link>
                                    <Link to="mint.necrodao.com" className="tf-button-st2 btn-effect"><span className="effect">MINT NOW</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="image">
                                <img src={item.img} alt="NecroDAO" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderItem;
