const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Deflationary & Rewarding',
        text : 'Transactions are taxed 8% - 3% is used for reflections - 2% is used for LP - 2% is for Development & Marketing - 1% is burned.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Prove your dedication',
        text : 'The members of the Underworld must prove their loyalty, by holding a NecroNFT, you gain access to voting rights to determine the future of NECRO.'
    },
    {
        id: 3,
        numb: '03',
        title: "Earn through NECRO",
        text : 'Our tokenomics are designed to reward the holders. By holding NECRO, you gain rewards paid in whatever host token the DAO decides on.'
    
    },
    {
        id: 4,
        numb: '04',
        title: "This is only the beginning",
        text : 'The end of what you know is here, we are reborn into a brighter and more rewarding future. '
    },
]
export default dataWork;
