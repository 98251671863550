import img1 from '../images/common/Necro1.png'
import img2 from '../images/common/Necro2.png'
import img3 from '../images/common/Necro3.png'
import img4 from '../images/common/Necro4.png'
import img5 from '../images/common/Necro5.png'
import img6 from '../images/common/Necro6.png'
import img7 from '../images/common/Necro7.png'
import img8 from '../images/common/Necro8.png'
import img9 from '../images/common/Necro9.png'
import img10 from '../images/common/Necro10.png'
import img11 from '../images/common/Necro11.png'
import img12 from '../images/common/Necro12.png'
import img13 from '../images/common/Necro13.png'
import img14 from '../images/common/Necro14.png'
import img15 from '../images/common/Necro15.png'
import img16 from '../images/common/Necro16.png'
import img17 from '../images/common/Necro17.png'
import img18 from '../images/common/Necro18.png'
import img19 from '../images/common/Necro19.png'
import img20 from '../images/common/Necro20.png'
import img21 from '../images/common/Necro21.png'
import img22 from '../images/common/Necro22.png'
import img23 from '../images/common/Necro23.png'
import img24 from '../images/common/Necro24.png'
import img26 from '../images/common/img13.jpg'
import img27 from '../images/common/img14.jpg'


const dataProject = [
    {
        id: 1,

        img: img1,
    },
    {
        id: 2,
    
        img: img2,
    },
    {
        id: 3,
  
        img: img3,
    },
    {
        id: 4,
  
        img: img4,
    },
    {
        id: 5,
  
        img: img5,
    },
    {
        id: 6,
     
        img: img6,
    },
    {
        id: 7,
     
        img: img7,
    },
    {
        id: 8,
     
        img: img8,
    },

    {
        id: 9,
  
        img: img9,
    },
    {
        id: 10,
   
        img: img10,
    },
    {
        id: 11,
   
        img: img11,
    },
    {
        id: 12,
   
        img: img12,
    },
    {
        id: 13,
  
        img: img13,
    },
    {
        id: 14,
  
        img: img14,
    },
    {
        id: 15,
   
        img: img15,
    },
    {
        id: 16,
  
        img: img16,
    },
    {
        id: 17,
 
        img: img17,
    },
    {
        id: 18,
  
        img: img18,
    },
    {
        id: 19,
 
        img: img19,
    },
    {
        id: 20,

        img: img20,
    },
    {
        id: 21,
 
        img: img21,
    },
    {
        id: 22,
 
        img: img22,
    },
    {
        id: 23,
 
        img: img23,
    },
    {
        id: 24,
   
        img: img24,
    },
]

export default dataProject;