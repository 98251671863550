import React , { useState , useEffect } from 'react';

import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';

import './styles.scss';
import logo from '../../assets/images/logo/logo.png'
import Button from '../button';



const Header = () => {

    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} alt="Necro" /></NavLink>
                    </div>
                    <a href="https://snapshot.org/#/necrodao.eth"className="tf-button btn-effect"  target="_blank" data-toggle="modal" data-target="#popup_bid"><span className="effect">SnapShot</span></a>
                    <a href="https://mint.necrodao.com"className="tf-button btn-effect"  target="_blank" data-toggle="modal" data-target="#popup_bid"><span className="effect">MINT</span></a>
                    


            </div>
            </div>
        </header>
    );
}

export default Header;
