import img1 from '../images/post/imgpost11.jpg'
import img2 from '../images/post/imgpost13.jpg'
import img3 from '../images/post/imgpost12.jpg'

const dataBlog = [
    {
        id: 1,
        img : img1,
        category: 'Origin',
        title: 'The history of NecroDAO',
        time: '20 Jun 2022',
        text: 'Who are we? We do we come from?',
    },
    {
        id: 2,
        img : img2,
        category: 'Symbol',
        title: 'What is NecroDAO?',
        time: '20 Jun 2022',
        text: 'NECRO is a symbol that stands for equality among the fallen',
    },
    {
        id: 3,
        img : img3,
        category: 'Vision',
        title: 'NecroDAO: Immortal Defi Ecosystem',
        time: '20 Jun 2022',
        text: 'Created to benefit the worthy, Chosen by the sinisters',
    },
    {
        id: 4,
        img : img1,
        category: 'digitalart',
        title: 'NFT Guide: Why They Matter For Music?',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
    {
        id: 5,
        img : img2,
        category: 'nftartwork',
        title: 'Max Live X Tacnocde Matter NFT Music ',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
    {
        id: 6,
        img : img3,
        category: 'digitalart',
        title: 'NFT Guide: Why They Matter For Music?',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
]

export default dataBlog;
