import React from 'react';

import PageTitle from '../components/pagetitle';
import About from '../features/about/home-v2';
import dataAbout from '../assets/fake-data/data-about';


function AboutOne(props) {
    return (
        <div className='about'>
            <PageTitle title='About Us' />

            <About data={dataAbout} />

        </div>
    );
}

export default AboutOne;
