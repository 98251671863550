import img1 from '../images/common/dontFomo.jpg';
import img2 from '../images/common/beast.jpg';
import img3 from '../images/common/necro.jpg';


const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Dont_Fomo',
        position: 'Co-founder & Media Development',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/dont_FOMO'
            },
        
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: 'https://t.me/dont_fomo'
            },
           
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Beast Digitalzz',
        position: 'Co-Founder',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/8zoXoz8'
            },
         
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: 'https://t.me/beastdigitalz'
            },
         
        ]
    },
   

]

export default dataTeam;