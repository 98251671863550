import React , {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import Button from '../../components/button';
import AboutItem from './about-item';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'About us',
            title: 'What is NecroDAO?',
            desc : 'NecroDAO is a community aimed and funded DAO ecosystem that utilizes a NFT & reflection token to collectively build a successful portfolio of investments. During regular intervals, the community will vote on new host currencies as well as future DeFi products to advance the future of NECRO.',
            desc2 : 'Contract - 0x8ab272f658D3741eF92590Da8Ab70536236fa1c0',
            desc3 : 'Total Supply - 100,000,000'
        }
    )

    return (
        <section className="tf-section tf-about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p>{dataBlock.desc}</p>
                            <p>{dataBlock.desc2}</p>
                            <p>{dataBlock.desc3}</p>
                           
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
