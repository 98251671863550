import bgImg1 from '../images/background/bg-1.jpg';
import bgImg2 from '../images/background/bg-3.jpg';
import bgImg3 from '../images/background/bg-4.jpg';
import img1 from '../images/common/itemslider.png';

const dataSlider = [
    {
        id: 1,
        title: 'NecroDAO DeFi Ecosystem',
        desc : 'From the shadows we come... Born from darkness.. "Fear not the dead, fear the living..." ',
        bgImg: bgImg1,
        img : img1
    },
   

]

export default dataSlider;