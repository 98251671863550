import React from 'react';
import PropTypes from 'prop-types';

import { Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss'

Partner.propTypes = {
    data: PropTypes.array,
};

function Partner(props) {

    const {data} = props;

    return (
        <section className="tf-section-center partner">
      <div className="container">
        <div className="col">
          <div className="row justify-content-md-center">
            <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
              <p className="h8 sub-title">partnerS</p>
              <h4 className="title">NecroDAO Partners</h4>
            </div>
          </div>

          <div className="row justify-content-md-center">
            <div className="item-parner" style={{ textAlign: 'center' }} data-aos="fade-up" data-aos-duration="800">
              <Swiper
                modules={[Scrollbar, A11y]}
                spaceBetween={30}
                scrollbar={{ draggable: true }}
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                  },
                }}
              >
                {data.slice(0, 9).map((item, idx) => (
                  <SwiperSlide key={idx}>
                    <div className="image">
                      <img src={item.img} alt="NecroDao" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
}

export default Partner;