const dataFaq = [
    {
        id: 1,
        title: 'What are the NFTs?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How do i get NFTs?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
    },
    {
        id: 3,
        title: 'How can we buy your NFTs?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
    },
    {
        id: 4,
        title: 'Who are the team behind the project?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
    },
    {
        id: 5,
        title: 'How can we buy your NFTs?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
    },
    {
        id: 6,
        title: 'Who are the team behind the project?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
    },
]

export default dataFaq;