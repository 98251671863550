const dataRoadmap = [
    {
        id: 1,
        time: 'Q2/Q3 2022',
        list: [
            {
                text: 'Concept and Idea Development'
            },
            {
                text: 'Back-End & Smart Contracts'
            },
            {
                text: 'Front-End Design & NFTs'
            },
            {
                text: 'NFT Minting Dapp'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q4 2022',
        list: [
            {
                text: 'Release Website and Social Platforms'
            },
            {
                text: 'Grow community'
            },
            {
                text: 'Launch DEX trading'
            },
            {
                text: 'Enable Minting Contract and Dapp'
            },
               {
                text: 'Coingecko & CoinMarketCap'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q1 2023',
        list: [
            {
                text: 'Marketing Campaign & NFT Airdrop'
            },
            {
                text: 'Launch of DAO and Voting Platform'
            },
            {
                text: 'Partnership Announcement'
            },
         
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q2 2023',
        list: [
            {
                text: 'NecroWallet Development Starts'
            },
            {
                text: '2nd Dex listing'
            },
            {
                text: 'DeFi platform partnership'
            },
            {
                text: 'V2 Contract Deployed'
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Q3 2023',
        list: [
            {
                text: 'TBA'
            },
            {
                text: 'TBA'
            },
            {
                text: 'TBA'
            },
            {
                text: 'TBA'
            },
        ],
        positon: 'left'
    },
    
]

export default dataRoadmap;
