import HomeOne from "./HomeOne";

import AboutOne from "./AboutOne";


import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
import RoadMapTwo from "./RoadMapTwo";
import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import TeamDetail from "./TeamDetail";


const routes = [
  { path: '/', component: <HomeOne />},


  { path: '/about-v1', component: <AboutOne />},


  { path: '/community', component: <Community />},
  { path: '/faq', component: <FAQ />},
  { path: '/404', component: <Page404 />},
  { path: '/road-map-v1', component: <RoadMapOne />},
  { path: '/road-map-v2', component: <RoadMapTwo />},
  { path: '/nft-item', component: <NftItem />},
  { path: '/nft-item-details', component: <NftItemDetails />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/contact', component: <Contact />},
  { path: '/team-details', component: <TeamDetail />},

]

export default routes;