const dataAbout = [
    {
        id: 1,
        title: 'NECRO',
        desc: 'NECRO is a multi-currency rewards token that allows holders to earn reflections from any DEX traded token on the Ethereum network',
        class: 'active'
    },
    {
        id: 2,
        title: 'DAO',
        desc: 'The DAO is a governing group of The Transcended that decide the direction of the Necro portfolio by regularly voting on new tokens to pursue for reflections.',
    },
    {
        id: 3,
        title: 'NecroNFT',
        desc: 'The members of the DAO prove their worth and dedication through the acquisition of identity into the depths of the NECRO world. NecroNFTs will be airdropped to holders as well as purchasable through our dapp. ',
    },
]

export default dataAbout;
